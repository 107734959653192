import React from 'react';
import { Card, CardColumns } from 'react-bootstrap'
import Divider from '../../components/Divider'
import SlideShow from './SlideShow'
import { Link, graphql, useStaticQuery } from "gatsby"
import {TransitionLink} from "../../components/Transition/TransitionLink"
import Img from "gatsby-image"
import {FaPlusSquare} from 'react-icons/fa'

import './news.css'

const News: React.FC = () => {
  const data = useStaticQuery(query);
  const variants = {};
  return (
    <div id="page-news">
      <Divider text="NOVIDADES"/>
      <div className="container">
        <div className="row m-0">
          <div className="col-md-12 ">
            <SlideShow />
          </div>
        </div>
        
        <div className="d-flex flex-wrap">
          <CardColumns>
            {data.allStrapiArticles.edges.map( (item,key) => (
              <div className="card-visible" key={key}>
                <Card key={item.node.url}>
                  <Link to={`/${item.node.url}`}>
                    {item.node.image && <Img fluid={item.node.image.childImageSharp.fluid} alt="" />}
                    <Card.Body>
                      <Card.Title className="limit-text">{item.node.title}</Card.Title>
                    </Card.Body>
                  </Link>
                  {/*<Card.Footer className="text-muted">2 dias atrás</Card.Footer>*/}
                </Card>
              </div>
            )) }
          </CardColumns>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            {/*<button type="button" className="btn btn-lg btn-outline-info">CARREGAR MAIS<br/><FaPlusSquare/></button>*/}
          </div>
        </div>
      </div>
    </div>
  );
}

const query = graphql`
  query NewsQuery{
    allStrapiArticles{
      edges{
        node{
          id
          title
          url
          created_at
          image {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
export default News;